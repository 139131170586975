<!-- <ng-container *ngIf="userDetail && currentUrl !== '/login'; else loginBlock"> -->
  <app-header *ngIf="userRle == 1"></app-header>
  <app-site-admin-nav *ngIf="userRle == 2 || userRle == 7 ||  userRle == 11 || userRle == 8"></app-site-admin-nav>
  <app-corporate-admin-nav *ngIf="userRle == 6"></app-corporate-admin-nav>
  <app-operator-nav *ngIf="userRle == 3 || userRle == 9 "></app-operator-nav>
  <app-lab-header *ngIf="userRle == 10"></app-lab-header>
  <app-executive-nav *ngIf="userRle == 12"></app-executive-nav>
  <app-site-user-nav *ngIf="userRle == 13"></app-site-user-nav>
  <app-multisite-user-nav *ngIf="userRle == 14"></app-multisite-user-nav>
  <section class="mt-5r p-1">
    <router-outlet></router-outlet>
  </section>
<!-- </ng-container> -->
<!-- <ng-template #loginBlock>
  <router-outlet></router-outlet>
</ng-template> -->

