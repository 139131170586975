import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { Links } from "../models/report.model";

export type keyValue = {
  key: number;
  value: string;
};

@Injectable({
  providedIn: "root",
})
export class DataShareService {
  public links$$ = new BehaviorSubject<Links[]>([]);

  public selectedLink$$ = new Subject<Links>();

  public uploadType: keyValue[] = [
    { key: 1, value: "Online" },
    { key: 2, value: "Manual" },
  ];
  constructor() {}

  get links(): Links[] {
    return this.links$$.value;
  }
}
