import { Component,OnInit,HostListener } from '@angular/core';
import { MessageService } from 'primeng/api';
import { SessionService } from './session.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(private messageService: MessageService,private sessionService: SessionService) {}
  ngOnInit(): void {
    this.sessionService.startSessionTimeout();
  }
  @HostListener('window:mousemove')
  @HostListener('window:keydown')
  resetSessionTimeout(): void {
    this.sessionService.resetSessionTimeout();
  }
}
