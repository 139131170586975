import { Component, OnInit } from '@angular/core';
import { SiteService } from '../site/site.service';
import { UserManagementService } from '../user-management/user-management.service';

@Component({
  selector: 'app-operator-nav',
  templateUrl: './operator-nav.component.html',
  styleUrls: ['./operator-nav.component.css']
})
export class OperatorNavComponent implements OnInit {
  public isCollapsed = false;
  public isMenuCollapsed = true;
  public user: any;
  public siteinfo:any;
  pdfurl:any;
  sitename:any;
  constructor(private userService: UserManagementService,private siteService: SiteService) { }

  ngOnInit(): void {
    this.user = this.userService.getUserDetail();
    this.siteinfo=this.userService.getSiteDetail();
    this.pdfurl = this.siteService.getPDFUrl(this.siteinfo[0].sitepfd);
    this.sitename =this.siteinfo[0].site_name;
  }

  logout() {
    this.userService.logout();
  }

}
