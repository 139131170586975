<app-loader [loading]="loading"></app-loader>

<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12">
            <h5 class="title-text my-3">Periodic Reports</h5>
            <p-table #dt [value]="allReports" [paginator]="true" [rows]="10"
            [rowsPerPageOptions]="rowsPerPageOptions">
          
            <ng-template pTemplate="header">
              <tr class="blue-theme-bg text-left">
              
                <th class="blue-theme-bg text-left">S no.</th>
                <th class="blue-theme-bg text-left">Report Name</th>
                <th class="blue-theme-bg text-center">Actions</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
              <tr>
                <td>{{data.report_id}}</td>
                <td>{{data.report_name}}</td>
                <td  class="text-center">
                  <button class="btn btn-sm btn-success text-white m-1" (click)="getViewDetails(allocatedSite)">Generate Report</button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td [attr.colspan]="colspan" class="text-danger text-center" >No data avaliable</td>
              </tr>
            </ng-template>
            
          </p-table>
           
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-modal>
    <div class="confirmation-header">
      <h5 class="modal-title text-white">Date Range</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body my-3">
        <label><b>Select Date  : </b></label>&nbsp;&nbsp;
        <p-calendar  selectionMode="range" rangeSeparator="to" dateFormat="dd-mm-yy" [readonlyInput]="true" [(ngModel)]="periodicDateRange"></p-calendar>
    </div>
    <div class="confirmation-footer">
      <button type="button" class="btn btn-sm btn-warning m-1" (click)="periodicDateRangeSelect()">Yes</button>
      <button type="button" class="btn btn-sm btn-primary m-1" (click)="modal.close(false)">No</button>
    </div>
</ng-template>

