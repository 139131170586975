import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserManagementService } from '../user-management/user-management.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css']
})
export class MainPageComponent implements OnInit {

  userDetail: any;
  private storage = environment.storage;

  constructor(private router: Router, private userService: UserManagementService) {
  }

  ngOnInit(): void {
    this.userDetail = this.userService.getUserDetail();
  }

  get currentUrl(){
    return this.router.url;
  }

  get userRle() {
    if(this.userDetail?.role==14 && this.currentUrl=='/'){
      return this.userDetail?.role;
  }else if(this.userDetail?.role==14 && (this.currentUrl=='/reportlibrary/editreport/process' || this.currentUrl=='/reportlibrary/editreport/opex' || this.currentUrl=='/dashboard/opexdashboard')){
      return '';
    }else if(this.userDetail?.role==14 && this.currentUrl!='/'){
      return this.userDetail?.role;
    }else{
      return this.userDetail?.role;
    }
    
  }

}
