import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { UserManagementService } from '../user-management.service';
import { environment } from 'src/environments/environment';
import { interval, Subscription } from 'rxjs';
// import { CryptoJS} from 'crypto-js';
import * as CryptoJS from 'crypto-js';

import { SocialAuthService } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { SessionService } from 'src/app/session.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  private storage = environment.storage;
  loginForm: any;
  loading = 0;
  btnDisabled : any;
  lfcount : any;
  timeLeft: number = 60; 
  subscription: Subscription;
  user: SocialUser;
  isLogin : boolean;
  constructor(private fb: FormBuilder,
    private userService: UserManagementService,
    private messageService: MessageService,
    private authService: SocialAuthService,
    private sessionService: SessionService,
    private router: Router) { }

  ngOnInit(): void {
    this.btnDisabled = 0;
    this.isLogin = true;
    const str  = this.storage.getItem('falied-login-count');
    if(str) {
      const num = parseInt(str, 10);
      if(num > 5){
          this.btnDisabled = 1;
          this.timeLeft = 60;
          const source = interval(1000); 
          this.subscription = source.subscribe(() => {
            this.timeLeft--;
            
            if (this.timeLeft === 0) {
              this.subscription.unsubscribe();
            }
          });
        setTimeout(() => {
          this.btnDisabled = 0;
          const lfc = "0";
          this.storage.setItem('falied-login-count', lfc);
        }, 60000);
      }else{
        this.btnDisabled = 0;
      }
    }
    this.initForm();
  }
 
  initForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
  onLogin(event) {
    this.loading++;
    //open it when do password encryption  --- chetna
    let PasswordHash;
    let pas = this.loginForm.get('password').value;
    const hash = CryptoJS.MD5(pas);
    PasswordHash = hash.toString();
    this.loginForm.get('password').setValue(PasswordHash);
   
    const request = Object.assign({ 'purpose': 'login' }, this.loginForm.value);

    this.userService.login(request).then((response: any) => {
      this.loading--;
      if (response && response.status === 1 && response.user) {
        const siteInfo =  JSON.parse(JSON.stringify(response.user.allsites));
        delete response.user.allsites;
        this.userService.addUserDetail(response.user);
        this.userService.addSiteDetails(siteInfo);
        this.sessionService.resetSessionTimeout();
        if(response.user.role==13){
        this.router.navigate(['reportlibrary/editreport/process']);
        }else{
          this.router.navigate(['/']);  
        }
      } else {
        this.loginForm.get('password').setValue('');
        this.sendToastMessage('error', response.msg, 'Login Failed');
        const loginFailCount =   response.data.cntLoginAttempt;
        this.storage.setItem('falied-login-count', loginFailCount);
        if(loginFailCount) {
          const num = parseInt(loginFailCount, 10);
          if(num > 5){
              this.btnDisabled = 1;
              this.timeLeft = 60;
              const source = interval(1000); 
              this.subscription = source.subscribe(() => {
                this.timeLeft--;
                
                if (this.timeLeft === 0) {
                  this.subscription.unsubscribe();
                }
              });
            setTimeout(() => {
              this.btnDisabled = 0;
              const lfc = "0";
              this.storage.setItem('falied-login-count', lfc);
            }, 60000);
          }else{
            this.btnDisabled = 0;
          }
        }
       
      }
    }).catch(error => {
      this.sendToastMessage('error', 'Unable to login', 'Login Failed');
      this.loading--;
    });
  }
  onClickLogin(event){
   if(event == 'login'){
    this.isLogin = true;
   }else{
    this.isLogin = false;
   }
  }
  sendToastMessage(messageType, title, message) {
    this.messageService.add({ severity: messageType, summary: message, detail: title });
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => this.onSignIn(x));
  }
  signUpWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => this.onSignUp(x));
  }
  onSignIn(x) {
    let Details =({
      'purpose': 'glogin','email':x.email
    })
  
    this.loading++;
    this.userService.glogin(Details).then((response: any) => {
      this.loading--;
      if (response && response.status === 1 && response.user) {
        const siteInfo =  JSON.parse(JSON.stringify(response.user.allsites));
        delete response.user.allsites;
        this.userService.addUserDetail(response.user);
        this.userService.addSiteDetails(siteInfo);
        this.router.navigate(['/']);
      } else {
        this.sendToastMessage('error', response.msg, 'Login Failed');
      
      }
    }).catch(error => {
      this.sendToastMessage('error', 'Unable to SignIn', 'SignIn Failed');
      this.loading--;
    });
  }

  onSignUp(x) {
    let Details =({
      'purpose': 'gregister','role_id' : 2,'first_name': x.firstName,'email':x.email,'last_name':x.lastName,'user_name':x.firstName,'user_id':1,'password':'123567'
    })
  
    this.loading++;
    this.userService.glogin(Details).then((response: any) => {
      this.loading--;
      if (response && response.status === 1 && response.user) {
        const siteInfo =  JSON.parse(JSON.stringify(response.user.allsites));
        delete response.user.allsites;
        this.router.navigate(['/']);
      } else {
        this.sendToastMessage('success', response.msg, '');
      }
    }).catch(error => {
      this.sendToastMessage('error', 'Unable to Sign Up', 'Sign Up Failed');
      this.loading--;
    });
  }
}
