import { Injectable } from "@angular/core";
import {
  InternalMoment,
  StartOfEndOFUnit,
  AddSubUnit,
} from "../utility/internal-moment";

@Injectable({
  providedIn: "root",
})
export class InternalMomentService {
  constructor() {}

  public moment(date?: Date | string): InternalMoment {
    return date ? new InternalMoment(date) : new InternalMoment();
  }

  public momentDate(date?: Date | string): Date {
    return date
      ? new InternalMoment(date).toDate()
      : new InternalMoment().toDate();
  }

  public startOf(unit: StartOfEndOFUnit, date?: Date | string): Date {
    return date
      ? this.moment(date).startOf(unit).toDate()
      : this.moment().startOf(unit).toDate();
  }

  public endOf(unit: StartOfEndOFUnit, date?: Date | string): Date {
    return date
      ? this.moment(date).endOf(unit).toDate()
      : this.moment().endOf(unit).toDate();
  }

  public add(amount: number, unit: AddSubUnit, date?: Date | string): Date {
    return date
      ? this.moment(date).add(amount, unit).toDate()
      : this.moment().add(amount, unit).toDate();
  }

  public subtract(
    amount: number,
    unit: AddSubUnit,
    date?: Date | string
  ): Date {
    return date
      ? this.moment(date).subtract(amount, unit).toDate()
      : this.moment().subtract(amount, unit).toDate();
  }

  public parseDate(date: string, format: string): Date {
    return this.moment().parse(date, format).toDate();
  }

  public parse(date: string, format: string): InternalMoment {
    return this.moment().parse(date, format);
  }

  public checkDateInRange(startDate: Date, endDate: Date, date: Date): boolean {
    return date >= startDate && date <= endDate;
  }

  public getLastDateOfMonth(year: number, month: number): Date {
    return new Date(year, month, 0);
  }
}
