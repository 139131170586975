<nav class="navbar navbar-expand-lg bg-custom boxshadow fixed-top">
  <a class="navbar-brand" routerLink="/">
    <span>
      <img class="logo" src="./assets/images/logo_new.png">
    </span>
  </a>
  <span style="font-size:18px;color:#fff;font-weight:bold;font-family: Lucida Grande, Lucida Sans Unicode, Arial, Helvetica, sans-serif;">{{sitename}}</span>
  <button class="navbar-toggler" (click)="isMenuCollapsed = !isMenuCollapsed">
    &#9776;
  </button>

  <div id="wwtp" class="collapse navbar-collapse" [ngbCollapse]="isMenuCollapsed">

    <ul class="navbar-nav ml-auto">
      <li class="nav-item mt-14px" ngbDropdown>
        <a class="nav-link text-white" ngbDropdownToggle id="dashboard" role="button">
          DASHBOARD <i class="fa fa-angle-down" aria-hidden="true"></i>
        </a>
        <ul ngbDropdownMenu aria-labelledby="dashboard"
          class="dropdown-menu bg-light top-52px  shadow user_role border-none user-menus static">
          <li ngbDropdown placement="left">
            <a class="sub-dropdown" id="Dashboard" ngbDropdownToggle>Process DB<i
                class="fa fa-caret-right  float-right mt-5px" aria-hidden="true"></i></a>
                <div class="dropdown-divider"></div>
            <div ngbDropdownMenu aria-labelledby="Dashboard"
              class="dropdown-menu bg-light user-menus sub-dropdown-menu user_role sub_opex_drop">
              <a ngbDropdownItem routerLink="/"> Default DB</a>
              <div class="dropdown-divider"></div>
              <a ngbDropdownItem routerLink="/site/sitedashboard"> Custom DB</a>
            </div>
          </li>

          <li ngbDropdown placement="left">
            <a class="sub-dropdown" id="Dashboard" ngbDropdownToggle>Opex Dashboard<i
                class="fa fa-caret-right  float-right mt-5px" aria-hidden="true"></i></a>
            <div ngbDropdownMenu aria-labelledby="Dashboard"
              class="dropdown-menu bg-light user-menus sub-dropdown-menu user_role sub_opex_drop">
              <a ngbDropdownItem routerLink="/dashboard/opexdashboard"> Default DB</a>
              <div class="dropdown-divider"></div>
              <a ngbDropdownItem routerLink="/dashboard/customeOpexDashboard"> Custom DB</a>
            </div>
          </li>

        </ul>
      </li>

      <li class="nav-item mt-14px" ngbDropdown>
        <a class="nav-link text-white" ngbDropdownToggle id="static" role="button">
          ALERTS <i class="fa fa-angle-down" aria-hidden="true"></i>
        </a>
        <ul ngbDropdownMenu aria-labelledby="static"
          class="dropdown-menu bg-light top-52px  shadow border-none user-menus static">
          <li ngbDropdown placement="left">
            <a class="sub-dropdown" routerLink="/alerts/openalert" ngbDropdownItem>Open Alert</a>
            <div class="dropdown-divider"></div>
            <a class="sub-dropdown" routerLink="/alerts/historyalert" ngbDropdownItem>History Alert</a>
          </li>
        </ul>
      </li>


      <li class="nav-item mt-14px" ngbDropdown *ngIf="user?.role == 3">
        <a class="nav-link text-white" ngbDropdownToggle id="static" role="button">
          UPLOADS <i class="fa fa-angle-down" aria-hidden="true"></i>
        </a>
        <ul ngbDropdownMenu aria-labelledby="static"
          class="dropdown-menu bg-light top-52px  shadow border-none user-menus static">
          <li ngbDropdown placement="left">
            <a class="sub-dropdown" routerLink="/site/uploads/process" ngbDropdownItem>Process Module</a>
            <div class="dropdown-divider"></div>
            <a class="sub-dropdown" routerLink="/site/uploads/opex" ngbDropdownItem>Opex Module</a>
          </li>
        </ul>
      </li>




      <li> <a class="nav-link mt-10px"><i class="fa fa-plus-square mt-10px"></i> </a></li>
      <li class="nav-item mt-12px" *ngIf="siteinfo && siteinfo[0].sitepfd && ['2','3', '7','11', '8', '9'].includes(user?.role)"><span><a class="nav-link text-white"  target="_blank" href={{pdfurl}}> View PFD</a></span> </li>
      <li class="nav-item ml-1" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="userauth" role="button">
          <img class="" src="./assets/images/avathar.jpg" height="40"
            style="border-radius: 50%;margin-top: 0px;height: 35px;">
        </a>
        <ul ngbDropdownMenu aria-labelledby="userauth" class="dropdown-menu bg-light top-52px logout shadow user-menus">
          <li ngbDropdown placement="left">
            <a class="sub-dropdown" id="logout" (click)="logout()" ngbDropdownToggle><i class="fa fa-power-off"></i>
              Logout </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</nav>
