import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { UserManagementService } from 'src/app/user-management/user-management.service';
import { SiteService } from 'src/app/site/site.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  public chForm: any;
  public userDetail : any;
  public siteinfo : any = [];
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() recordSaved: EventEmitter<any> = new EventEmitter<any>();
  constructor(private fb: FormBuilder, private userService: UserManagementService,private siteService: SiteService,
    private messageService: MessageService) { }

  ngOnInit(): void {
    this.userDetail = this.userService.getUserDetail();
    this.siteinfo=this.userService.getSiteDetail();
    this.initForm();
  }

  initForm() {
    const form = {
      user_id : [this.userDetail.id],
      old_password: ['', Validators.required],
      new_password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!])[A-Za-z\d@#$%^&+=!]{8,}$/),
        ],
      ],
      re_password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!])[A-Za-z\d@#$%^&+=!]{8,}$/),
        ],
      ],
      
    };
   
    this.chForm = this.fb.group(form);
  }

  chSubmit(event) {
    const request = JSON.parse(JSON.stringify(this.chForm.value));
    request.user_id = this.userDetail.id;
    request.purpose = 'userChangeNewPassword';
    if(request.old_password == this.userDetail.password){
      if(request.new_password == request.re_password){
        this.siteService.changeLabUserPassword(request).then((response: any) => {
          if (response && response.status === 1) {
            this.sendToastMessage('success', response.msg, 'Please login!');
            this.chForm.reset();
            this.recordSaved.emit();
            this.userService.logout();
          } else {
            this.sendToastMessage('error', response.msg, 'Failed');
          }
        }).catch(error => {
          this.sendToastMessage('error', 'Unable to change password', 'Failed');
        });
      }else{
        this.sendToastMessage('error', 'Unable to Change Password', 'New Password and Re-enter Password are not Matched!');
      }
    }else{
      this.sendToastMessage('error', 'Unable to Change Password', 'Old Password is not Correct!');
    }
  }

  sendToastMessage(messageType, title, message) {
    this.messageService.add({ severity: messageType, summary: message, detail: title });
  }
  onCancel() {
    this.cancel.emit();
  }
}
