<div class="row">
    <div class="col-sm-12">
      <div class="card p-1 pr-2">
        <div class="card-content collapse show">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <h4 class="text-danger text-center">Under Development</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
