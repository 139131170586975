import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SessionService {
  // private sessionTimeout = 1800000; // 30 minutes in milliseconds
  private sessionTimeout = 7 * 24 * 60 * 60 * 1000; // 7 days * 24 hours/day * 60 minutes/hour * 60 seconds/minute * 1000 milliseconds/second
  private timeoutHandle: number;
  private storage = environment.storage;
  public selectedSite = null;
  constructor(private router: Router) {}

  public startSessionTimeout(): void {
    this.timeoutHandle = window.setTimeout(() => {
      this.storage.removeItem('user-info');
      this.storage.removeItem('sites-info');
      this.storage.removeItem('module-info');
      sessionStorage.removeItem('current-site');
      localStorage.removeItem('completeModule');
      localStorage.removeItem('corporateModule');
      localStorage.removeItem('labModule');
      localStorage.removeItem('editModule');
      localStorage.removeItem('GROUP')
      this.selectedSite = null;
      this.router.navigate(['/login']);
    }, this.sessionTimeout);
  }

  public resetSessionTimeout(): void {
    window.clearTimeout(this.timeoutHandle);
    this.startSessionTimeout();
  }
}
