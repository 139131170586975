import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SiteService } from 'src/app/site/site.service';
import { UserManagementService} from 'src/app/user-management/user-management.service'

@Component({
  selector: 'app-chatpopup',
  templateUrl: './chatpopup.component.html',
  styleUrls: ['./chatpopup.component.css']
})
export class ChatpopupComponent implements OnInit {
  query:any;
  loading=0;
  chatdata=[];
  userDetail:any;
  dynamicHTML:any;

  constructor(public activeModal: NgbActiveModal,private modalserivce: NgbModal,private siteService: SiteService, private userService:UserManagementService) { }

  ngOnInit(): void {
    this.userDetail = this.userService.getUserDetail();
  }

  close() {
    this.activeModal.dismiss('Cross click');
  }
  getCustomQuery(){
    this.loading++;
    const updquery = this.query + 'Please provide the response in HTML format.';
    let request={'message':updquery}
    this.siteService.getChatInfo(request).then((response: any) => {
      if(response.status==1){
        const substr=this.getSubstringAfter(response.message,'<body>')
        const result = this.substringBefore(substr, '</html>').trim();
        const fresult = this.substringBefore(result, '</body>');
        //console.log(fresult)
        this.chatdata.push(fresult);
        //this.dynamicHTML=fresult;
        
      }
    }).catch(error => {console.log(error)}).finally(() => this.loading--);
  }
  getSubstringAfter(inputString: string, searchString: string): string | null {
    // Find the index of the search string in the input string
    const index = inputString.indexOf(searchString);

    // If the search string is not found, return null
    if (index === -1) {
        return null;
    }

    // Get the substring after the search string
    const substringAfter = inputString.substring(index + searchString.length);

    return substringAfter;
}

substringBefore(inputString: string, delimiter: string): string {
  const index = inputString.indexOf(delimiter);
  if (index !== -1) {
      return inputString.substring(0, index);
  } else {
      return inputString; // If delimiter not found, return the entire input string
  }
}

}
